import { parseFaqs, parseSchemaBreadcrums } from './parsingSchema';

import { Helmet } from 'react-helmet';
import { getLCPImageURL } from './LCPUrl';
import { metaDataService } from 'core/services/metaDataService';
import parseBreadcrumbs from 'core/infrastructure/parseBreadcrumbs';

interface SeoProps {
  content: any;
}

export const Seo = ({ content }: SeoProps) => {
  const LCPImage = getLCPImageURL(content);
  let faqsValues;
  let bcs;
  switch (content.bundle) {
    case 'template': {
      const breadcrumbs = parseBreadcrumbs(content, 'template');
      bcs = parseSchemaBreadcrums(breadcrumbs);
      break;
    }
    case 'template_category': {
      faqsValues = content.faqs && content.faqs.entity ? content?.faqs?.entity?.items : null;
      const breadcrumbs = parseBreadcrumbs(content, 'template_category');
      bcs = parseSchemaBreadcrums(breadcrumbs);
      break;
    }
    case 'landing': {
      const result = content.paragraphs.filter((obj: any) => {
        return obj.entity.type === 'ParagraphAccordeon';
      });
      faqsValues = result[0] ? result[0].entity.items : null;
      break;
    }
    default: {
      break;
    }
  }
  const FAQS = parseFaqs(faqsValues);
  const links = metaDataService.getLinks(content);
  links?.push({ rel: 'preload', href: LCPImage, as: 'image' });
  return (
    <Helmet
      htmlAttributes={{
        'lang': content?.langcode?.value,
        'dir': 'ltr',
        'nodeId': content?.id,
        'prefix':
          'content: http://purl.org/rss/1.0/modules/content/  dc: http://purl.org/dc/terms/  foaf: http://xmlns.com/foaf/0.1/  og: http://ogp.me/ns#  rdfs: http://www.w3.org/2000/01/rdf-schema#  schema: http://schema.org/  sioc: http://rdfs.org/sioc/ns#  sioct: http://rdfs.org/sioc/types#  skos: http://www.w3.org/2004/02/skos/core#  xsd: http://www.w3.org/2001/XMLSchema# ',
        'xmlns': 'http://www.w3.org/1999/xhtml',
        'xmlns:fb': 'https://www.facebook.com/2008/fbml',
        'xmlns:og': 'http://ogp.me/ns#',
      }}
      title={metaDataService.getTitle(content)}
      meta={metaDataService.getMetas(content)}
      link={links}
      defer={false}
    >
      <script type="application/ld+json">{content?.metasSchema}</script>
      {FAQS && (
        <script className="faqs" type="application/ld+json">
          {JSON.stringify(FAQS)}
        </script>
      )}
      {bcs && (
        <script className="breadcrumbs" type="application/ld+json">
          {JSON.stringify(bcs)}
        </script>
      )}
    </Helmet>
  );
};

Seo.defaultProps = {
  language: 'en',
  meta: [],
  link: [],
  schema: {},
};
