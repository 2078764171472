import { Color, ComponentContainer, HtmlParser } from '@genially/public-web-components';
import type { FC } from 'react';

interface ParagraphFullHtmlProps {
  behaviorSettings: {
    rowStyle: { background: { baseColor: { color: Color } } };
  };
  content?: { value: string };
  first?: boolean;
}

export const ParagraphFullhtml: FC<ParagraphFullHtmlProps> = ({ content, first }) => {
  return (
    <ComponentContainer style={!first ? { padding: '0' } : {}}>
      {content && <HtmlParser>{content.value}</HtmlParser>}
    </ComponentContainer>
  );
};
