import { translate } from 'core/infrastructure/translator';

function getCDPurl(content: any) {
  let url = '/templates/';
  const lang = content.langcode.value;
  switch (lang) {
    case 'pt-br': {
      url = '/' + lang + '/modelos/';
      break;
    }
    case 'es': {
      url = '/' + lang + '/plantillas/';
      break;
    }
    case 'de': {
      url = '/' + lang + '/vorlagen/';
      break;
    }
    case 'fr': {
      url = '/' + lang + '/modeles/';
      break;
    }
    case 'it': {
      url = '/' + lang + '/templates/';
      break;
    }
    default: {
      url = '/templates/';
      break;
    }
  }
  return url;
}

export default function parseBreadcrumbs(content: any, type: string): any {
  if (type === 'template') {
    const translateFromContent = (key: string) => translate(content.langcode.value, key);
    let breadcrumbs;
    const urlMainCategory =
      parseCategories(content.categories) &&
      parseCategories(content.categories)[0] &&
      parseCategories(content.categories)[0].length > 0
        ? parseCategories(content.categories)[0][0].url.path
        : null;

    const titleMainCategory =
      parseCategories(content.categories) &&
      parseCategories(content.categories)[0] &&
      parseCategories(content.categories)[0].length > 0
        ? parseCategories(content.categories)[0][0].fieldDisplayTitle3
          ? parseCategories(content.categories)[0][0].fieldDisplayTitle3
          : parseCategories(content.categories)[0][0].fieldDisplayTitle2
        : null;

    const urlSecondaryCategory =
      parseCategories(content.categories) &&
      parseCategories(content.categories)[1] &&
      parseCategories(content.categories)[1].length > 0
        ? parseCategories(content.categories)[1]
          ? parseCategories(content.categories)[1][0].url.path
          : null
        : '';
    const titleSecondaryCategory =
      parseCategories(content.categories) &&
      parseCategories(content.categories)[1] &&
      parseCategories(content.categories)[1].length > 0
        ? parseCategories(content.categories)[1]
          ? parseCategories(content.categories)[1][0].fieldDisplayTitle3
            ? parseCategories(content.categories)[1][0].fieldDisplayTitle3
            : parseCategories(content.categories)[1][0].fieldDisplayTitle2
          : ''
        : null;
    if (!urlMainCategory && !urlSecondaryCategory) {
      breadcrumbs = [
        { url: getCDPurl(content), title: translateFromContent('templates.breadcrumbback') },
        { url: content.url.path, title: content.displayTitle },
      ];
    }
    if (urlMainCategory && !urlSecondaryCategory) {
      breadcrumbs = [
        { url: getCDPurl(content), title: translateFromContent('templates.breadcrumbback') },
        { url: urlMainCategory, title: titleMainCategory },
        { url: content.url.path, title: content.displayTitle },
      ];
    }
    if (urlSecondaryCategory) {
      breadcrumbs = [
        { url: getCDPurl(content), title: translateFromContent('templates.breadcrumbback') },
        { url: urlMainCategory, title: titleMainCategory },
        { url: urlSecondaryCategory, title: titleSecondaryCategory },
        { url: content.url.path, title: content.displayTitle },
      ];
    }
    return breadcrumbs;
  }

  if (type === 'template_category') {
    const translateFromContent = (key: string) => translate(content.langcode.value, key);

    const parent = content.categories[0].category.parent;
    const parentEnt = parent
      ? content.custom.data.content.entities.filter(
          (category: any) => category.tid === parent[0].entity.tid
        )
      : null;

    let breadcrumbs = [];

    const parentEntity = parentEnt ? parentEnt[0]?.mainRelatedContents?.entities[0] : null;

    parentEntity
      ? (breadcrumbs = [
          { url: getCDPurl(content), title: translateFromContent('templates.breadcrumbback') },
          {
            url: parentEntity ? parentEntity.url.path + '/' : null,
            title: parentEntity ? parentEntity.fieldDisplayTitle3 : null,
          },
          {
            url: content.url.path,
            title: content.displayTitle3 ? content.displayTitle3 : content.displayTitle2,
          },
        ])
      : (breadcrumbs = [
          { url: getCDPurl(content), title: translateFromContent('templates.breadcrumbback') },
          {
            url: content.url.path,
            title: content.displayTitle3 ? content.displayTitle3 : content.displayTitle2,
          },
        ]);

    return breadcrumbs;
  }
  return null;
}

export const parseCategories = (categories?: any): any => {
  if (!categories) return;
  const landings: any = [];
  categories.forEach((category: any) => {
    const nodes = category.category.mainRelatedContents
      ? category.category.mainRelatedContents.entities
      : null;
    const landing = nodes
      ? nodes.filter((template: any) => template.bundle === 'template_category')
      : null;
    if (!category.category.parent && !landings[0]) {
      landings[0] = landing;
    }
    if (category.category.parent && category.category.parent.length > 0 && !landings[1]) {
      landings[1] = landing;
    }
  });

  return landings;
};
