import { Paragraph, ParagraphsFactory } from 'components/ParagraphsFactory';

import type { FC } from 'react';

interface DataProps {
  content: any;
}

const MegamenuContent: FC<DataProps> = ({ content }) => {
  const paragraphs = content.paragraphs?.map(({ entity }: { entity: Paragraph }) => ({
    type: entity.type,
    content: entity,
  }));
  return (
    <div
      style={{
        maxWidth: '1014px',
      }}
    >
      {paragraphs && (
        <ParagraphsFactory
          paragraphs={paragraphs}
          fullNode={content}
          langcode={content.langcode.value}
        />
      )}
    </div>
  );
};

export default MegamenuContent;
