import type { FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import isNil from 'lodash/isNil';

const DOMAIN = 'genially.com';

export const isInternal = (href: string): boolean => {
  let isInternal = false;
  if (isNil(href)) return true;
  if (href.startsWith('/')) isInternal = true;
  if (href.startsWith('#')) isInternal = true;
  if (href.startsWith(DOMAIN)) isInternal = true;
  if (
    href.includes('://auth.') ||
    href.includes('://www.auth.') ||
    href.includes('www.auth.') ||
    href.includes('://newweb.') ||
    href.includes('://www.newweb.') ||
    href.includes('www.newweb.') ||
    href.includes('://' + DOMAIN) ||
    href.includes('://www.' + DOMAIN) ||
    href.includes('www.' + DOMAIN)
  ) {
    isInternal = true;
  }

  return isInternal;
};

export const Link: FC<any> = ({ children, ...rest }) => {
  if (isInternal(rest.href)) {
    return <GatsbyLink {...rest}>{children}</GatsbyLink>;
  }

  return (
    <a {...rest} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
};
