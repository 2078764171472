import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';
import { Color, CustomSpacing, TabTextItem, Tabs } from '@genially/public-web-components';

import type { FC } from 'react';
import type { MediaIframeDTO } from 'core/domain/Media/MediaIframeDTO';
import type { MediaImageDTO } from 'core/domain/Media/MediaImageDTO';
import type { MediaVideoDTO } from 'core/domain/Media/MediaVideoDTO';
import { parseMedia } from 'core/infrastructure/parseMedia';

interface ParagraphTabsStyle1Props {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  heading?: string;
  headingType?: string;
  headingColor?: Color;

  title?: string;
  titleType?: string;
  titleColor?: Color;

  paragraph?: { value: string };
  paragraphColor?: Color;

  items?: TabsStyle1ItemDTO[];

  button?: { url: { path: string }; title: string };
  buttonStyle?: { size: string; variant: string };
  animation?: string;
}

export interface TabsStyle1ItemDTO {
  item: {
    tabTitle: string;
    tabTitleType?: string;
    tabTitleColor: Color;
    flipMobile?: boolean;
    mediaBorRadius?: number;
    media?: MediaIframeDTO | MediaVideoDTO | MediaImageDTO;

    heading?: string;
    headingType?: string;
    headingColor?: Color;

    title?: string;
    titleType?: string;
    titleColor?: Color;

    paragraph?: { value: string };
    paragraphColor?: Color;
    paragraphType?: string;

    button?: { url: { path: string }; title: string };
    buttonStyle?: { size: string; variant: string };
  };
}

export const ParagraphTabsStyle1: FC<ParagraphTabsStyle1Props> = ({
  behaviorSettings,
  heading,
  headingType,
  headingColor,
  title,
  titleType,
  titleColor,
  paragraph,
  paragraphColor,
  items,
  animation = 'fadeInUp',
}) => {
  return (
    <Tabs
      animation={animation}
      background={parseBackground(behaviorSettings.rowStyle.background)}
      customSpacing={behaviorSettings.rowStyle.spacing}
      type="text"
      heading={
        heading ? { text: heading, color: headingColor ?? 'primary', type: headingType } : undefined
      }
      title={title ? { text: title, color: titleColor ?? 'black', type: titleType } : undefined}
      paragraph={
        paragraph ? { text: paragraph.value, color: paragraphColor ?? 'black' } : undefined
      }
      tabs={items ? parseTabsStyle1Items(items) : undefined}
    />
  );
};

export const parseTabsStyle1Items = (itemsDTO: TabsStyle1ItemDTO[] = []): TabTextItem[] => {
  if (!itemsDTO) return [];

  return itemsDTO.map((itemDTO: TabsStyle1ItemDTO) => {
    return {
      title: itemDTO.item.title
        ? {
            text: itemDTO.item.title,
            type: itemDTO.item.titleType,
            color: itemDTO.item.titleColor,
          }
        : undefined,
      tabTitle: {
        text: itemDTO.item.tabTitle,
        type: itemDTO.item.tabTitleType,
        color: itemDTO.item.tabTitleColor,
      },
      flipMobile: itemDTO.item.flipMobile,
      mediaBorRadius: itemDTO.item.mediaBorRadius ? itemDTO.item.mediaBorRadius : 0,
      heading: itemDTO.item.heading
        ? {
            text: itemDTO.item.heading,
            type: itemDTO.item.headingType,
            color: itemDTO.item.headingColor,
          }
        : undefined,
      paragraph: itemDTO.item.paragraph
        ? {
            text: itemDTO.item.paragraph.value,
            color: itemDTO.item.paragraphColor,
            type: itemDTO.item.paragraphType,
          }
        : undefined,
      button:
        itemDTO.item.button && itemDTO.item.buttonStyle
          ? {
              variant: itemDTO.item.buttonStyle.variant,
              size: itemDTO.item.buttonStyle.size,
              children: itemDTO.item.button.title,
              href: itemDTO.item.button.url.path,
            }
          : undefined,
      media: parseMedia(itemDTO.item.media),
    };
  });
};
