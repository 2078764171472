/* eslint-disable sort-imports */
import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';
import { CustomSpacing, Links } from '@genially/public-web-components';

import type { FC } from 'react';

export interface ParagraphLinksProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  links: Link[];
  animation?: string;
  size?: string;
}

export interface Link {
  url: any;
  title: string;
}

export const ParagraphLinks: FC<ParagraphLinksProps> = ({
  behaviorSettings,
  links,
  size,
  animation = 'fadeInUp',
}) => {
  return (
    <Links
      links={parseLinks(links)}
      animation={animation}
      background={parseBackground(behaviorSettings?.rowStyle.background)}
      customSpacing={behaviorSettings?.rowStyle.spacing}
      size={size ? size : 'medium'}
    />
  );
};

export const parseLinks = (links: Link[] = []): any => {
  if (!links) return [];
  return links.map((link: any) => {
    return {
      link: link?.link?.url?.path,
      title: link?.link?.fieldDisplayTitle3,
    };
  });
};
