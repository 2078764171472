import { Cards, Color, CustomSpacing, TextAttributes } from '@genially/public-web-components';

import type { BackgroundDTO } from 'core/infrastructure/parseBackground';
import type { FC } from 'react';
import { parseBackground } from 'core/infrastructure/parseBackground';
import { parseMedia } from 'core/infrastructure/parseMedia';

interface ParagraphCardsGridProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  title?: string;
  titleType?: string;
  titleColor?: Color;
  heading?: string;
  headingType?: string;
  headingColor?: Color;
  paragraph?: { processed: string };
  paragraphColor?: Color;
  cards?: ParagraphCardsGridItemDTO[];
  button?: { url: { path: string }; title: string };
  buttonStyle?: { size: string; variant: string };
  elementsPerRow?: number;
  categoryWrapper?: TemplateCategory;
  numberOfTemplates?: any;
  masonry?: boolean;
  langcode?: any;
}

export interface ParagraphCardsGridItemDTO {
  entity: any;
}
export interface TemplateCategory {
  category: {
    tid: string;
    mainRelatedContents: {
      entities: any[];
    };
  };
}

export const ParagraphCardsGrid: FC<ParagraphCardsGridProps> = ({
  behaviorSettings,
  title,
  titleType,
  titleColor,
  heading,
  headingType,
  headingColor,
  paragraph,
  paragraphColor,
  cards,
  button,
  buttonStyle,
  elementsPerRow = 2,
  categoryWrapper,
  numberOfTemplates = 12,
  masonry,
  langcode,
}) => {
  const templates = categoryWrapper
    ? categoryWrapper.category.mainRelatedContents.entities?.filter(
        (template: any) => template.bundle === 'template'
      )
    : [];

  let maxTemplates = categoryWrapper
    ? templates.length <= numberOfTemplates
      ? templates.length
      : numberOfTemplates
    : numberOfTemplates;
  maxTemplates = numberOfTemplates === 0 ? templates.length : numberOfTemplates;

  let categoryTemplates = templates.slice(0, maxTemplates);

  categoryTemplates = categoryTemplates?.filter((template: any) => template.bundle === 'template');

  return (
    <Cards
      variant="grid"
      background={parseBackground(behaviorSettings.rowStyle.background)}
      customSpacing={behaviorSettings.rowStyle.spacing}
      elementsPerRow={elementsPerRow}
      masonry={masonry}
      heading={
        heading
          ? {
              text: heading,
              type: headingType,
              color: headingColor,
            }
          : undefined
      }
      title={
        title
          ? {
              text: title,
              type: titleType,
              color: titleColor,
            }
          : undefined
      }
      paragraph={
        paragraph
          ? {
              text: paragraph.processed,
              color: paragraphColor,
            }
          : undefined
      }
      cards={
        categoryWrapper
          ? parseCardsCarouselTemplateItems(categoryTemplates, langcode)
          : parseCardsGridItems(cards, langcode)
      }
      templateCard={categoryWrapper ? true : false}
      button={
        button && buttonStyle
          ? {
              variant: buttonStyle.variant,
              size: buttonStyle.size,
              children: button.title,
              href: button.url.path,
            }
          : undefined
      }
    />
  );
};

interface CardItem {
  media?: any;
  subtitle?: TextAttributes;
  title?: TextAttributes;
  url?: string;
}

export const parseCardsGridItems = (
  items?: ParagraphCardsGridItemDTO[],
  langcode?: string
): CardItem[] | undefined => {
  if (!items || items.length == 0) return undefined;

  return items.map((item: ParagraphCardsGridItemDTO) =>
    item.entity.create
      ? item.entity.create.entity.lang?.value !== langcode
        ? { disabled: true }
        : {
            media: item.entity.create.entity.media
              ? parseMedia(
                  item.entity.create.entity.media,
                  true,
                  langcode,
                  item.entity.create.entity.displayTitle
                    ? item.entity.create.entity.displayTitle
                    : ''
                )
              : undefined,
            title: item.entity.create.entity.displayTitle
              ? { text: item.entity.create.entity.displayTitle }
              : undefined,
            url: item.entity.create.entity.url ? item.entity.create.entity.url.path : undefined,
          }
      : item.entity.template
      ? {
          media: item.entity.template.entity.teaserImage
            ? parseMedia(
                item.entity.template.entity.teaserImage,
                true,
                langcode,
                item.entity.template.entity.displayTitle
                  ? item.entity.template.entity.displayTitle
                  : ''
              )
            : undefined,
          premium: item.entity.template.entity.price,
          title: item.entity.template.entity.displayTitle
            ? { text: item.entity.template.entity.displayTitle }
            : undefined,
          url: item.entity.template.entity.url ? item.entity.template.entity.url.path : undefined,
          roundedBorder: true,
        }
      : {
          upperMedia: item.entity.upperMedia ? parseMedia(item.entity.upperMedia) : undefined,
          media: item.entity.media ? parseMedia(item.entity.media) : undefined,
          title: item.entity.title
            ? {
                text: item.entity.title,
                color: item.entity.titleColor,
                type: item.entity.titleType,
              }
            : undefined,
          heading: item.entity.heading
            ? {
                text: item.entity.heading,
                type: item.entity.headingType,
                color: item.entity.headingColor,
              }
            : undefined,
          paragraph: item.entity.paragraph
            ? {
                text: item.entity.paragraph.processed,
                color: item.entity.paragraphColor,
              }
            : undefined,
          url: item.entity.url ? item.entity.url.url.path : undefined,
          roundedBorder: false,
        }
  );
};

export const parseCardsCarouselTemplateItems = (
  items?: any[],
  langcode?: string
): any[] | undefined => {
  if (!items || items.length == 0) return undefined;

  return items.map((item: any) => ({
    media: item.teaserImage
      ? parseMedia(item.teaserImage, true, langcode, item.displayTitle ? item.displayTitle : '')
      : undefined,
    premium: item.price,
    title: item.displayTitle ? { text: item.displayTitle } : undefined,
    url: item.url ? item.url.path : undefined,
  }));
};
