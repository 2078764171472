import { useEffect, useState } from 'react';

import { useLocation } from '@reach/router';

export const OneTap = () => {
  const [logout, setLogout] = useState('false');
  const location = useLocation();
  useEffect(() => {
    setLogout(location.search == '?logout=true' ? 'false' : 'true');
  }, []);
  if (process.env.GATSBY_ENVIROMENT != 'production') return <></>;
  return (
    <>
      <div
        id="g_id_onload"
        data-client_id="557865689185-vp9t1g9ilmcjo9ir53424mknh4ja3oe5.apps.googleusercontent.com"
        data-login_uri="https://auth.genially.com/callback/one-tap"
        data-state_cookie_domain="genially.com"
        data-skip_prompt_cookie="gen.info"
        data-auto_select={logout}
        data-context="signup"
        data-use_fedcm_for_prompt="true"
        style={{
          position: 'absolute',
          top: '100px',
          right: '30px',
          width: '100px',
          height: 0,
          zIndex: 1001,
        }}
      ></div>
      <script src="https://accounts.google.com/gsi/client" defer></script>
    </>
  );
};
