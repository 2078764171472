import { Color, CustomSpacing, MediaContainer } from '@genially/public-web-components';

import type { FC } from 'react';
import type { MediaIframeDTO } from 'core/domain/Media/MediaIframeDTO';
import type { MediaImageDTO } from 'core/domain/Media/MediaImageDTO';
import type { MediaVideoDTO } from 'core/domain/Media/MediaVideoDTO';
import { parseBackground } from 'core/infrastructure/parseBackground';
import { parseMedia } from 'core/infrastructure/parseMedia';

export interface BackgroundDTO {
  backgroundType: 'image' | 'video';
  baseColor: {
    color: Color;
  };
  image?: {
    desktop?: ImageSource;
    laptop?: ImageSource;
    tablet?: ImageSource;
    mobile?: ImageSource;
  };
}

interface ImageSource {
  src: string;
}

interface ParagraphMediaProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
    layout_paragraphs: { parent_uuid: string; region: string };
  };
  media?: MediaIframeDTO | MediaVideoDTO | MediaImageDTO;
  mediaBorRadius?: number;
  boxShadowBol?: boolean;
}

export const ParagraphMedia: FC<ParagraphMediaProps> = ({
  behaviorSettings,
  media,
  mediaBorRadius,
  boxShadowBol,
}) => {
  return (
    <MediaContainer
      background={parseBackground(behaviorSettings.rowStyle.background)}
      customSpacing={behaviorSettings.rowStyle.spacing}
      media={parseMedia(media)}
      mediaBorRadius={mediaBorRadius}
      boxShadowBol={boxShadowBol}
    />
  );
};
