import configService from 'core/services/configService';

export const translate = (langcode: string, literal: string): string => {
  const literals = configService.data.languages[langcode].literals;
  if (!(literal in literals)) {
    const defaultLanguageLiterals =
      configService.data.languages[configService.data.defaultLanguage.langcode];
    if (literal in defaultLanguageLiterals) {
      return defaultLanguageLiterals[literal];
    }
    return '';
  }

  return literals[literal];
};
