export const getLCPImageURL = (content: any) => {
  let LCPimage = '';

  LCPimage = content?.paragraphs?.[0]
    ? content.paragraphs?.[0].entity.media?.entity.mediaImage
      ? content.paragraphs?.[0].entity.media?.entity.mediaImage?.src
      : null
    : null;

  /* TO-DO LCP for background images */

  return LCPimage;
};
