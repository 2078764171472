interface FAQ {
  '@type': string;
  'name': string;
  'acceptedAnswer': {
    text: string;
    type: string;
  };
}

interface BC {
  '@type': string;
  'position': number;
  'name': string;
  'item': string;
}

export function parseFaqs(faqs: Array<any>) {
  if (!faqs || faqs.length <= 0) return null;

  const faqsArr: FAQ[] = [];

  faqs.map((item: any) => {
    faqsArr.push({
      '@type': 'Question',
      'name': item.item.title,
      'acceptedAnswer': {
        text: item?.item?.paragraph?.processed,
        type: 'Answer',
      },
    });
  });

  const data = {
    '@context': 'https://schema.org/',
    '@type': 'FAQPage',
    'mainEntity': faqsArr,
  };

  return data;
}

export function parseSchemaBreadcrums(breadcrumb: Array<any>) {
  if (!breadcrumb || breadcrumb.length <= 0 || breadcrumb === undefined) return null;
  const bcArr: BC[] = [];

  breadcrumb.map((bc: any, i: number) => {
    bcArr.push({
      '@type': 'ListItem',
      'position': i + 1,
      'name': bc.title,
      'item': 'https://genially.com' + bc.url,
    });
  });

  const data = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': bcArr,
  };

  return data;
}
