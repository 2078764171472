import { translate } from 'core/infrastructure/translator';

const META_TYPE_VALUE = 'MetaValue';
const META_TYPE_PROPERTY = 'MetaProperty';
const META_TYPE_HTTP_EQUIV = 'MetaHttpEquiv';
const META_TYPE_ITEM_PROP = 'MetaItemProp';
const META_TYPE_LINK = 'MetaLink';
const META_TYPE_LINK_HREFLANG = 'MetaLinkHreflang';

const META_PROPERTY_TITLE = 'title';

interface Meta {
  type: string;
  property: string;
  content: any;
}

interface Content {
  title: string;
  metas?: Meta[];
  bundle?: string;
  langcode?: any;
}

const getTitle = (content: Content) => {
  const translateFromContent = (key: string) => translate(content.langcode.value, key);
  if (!content?.metas) {
    return content?.title;
  }
  const metaTitle = content.metas.find(
    (meta: Meta) => meta.type === META_TYPE_VALUE && meta.property === META_PROPERTY_TITLE
  );
  if (!metaTitle) {
    if (content.bundle === 'template') {
      return content.title + ' | ' + translateFromContent('templates.templatesutitle');
    }
    return content.title;
  }
  if (content.bundle === 'template') {
    return metaTitle.content + ' | ' + translateFromContent('templates.templatesutitle');
  }
  return metaTitle.content;
};

const getMetas = (content: Content) => {
  if (!content?.metas) {
    return undefined;
  }
  return content.metas
    .filter(
      (meta: Meta) =>
        meta.type === META_TYPE_VALUE ||
        meta.type === META_TYPE_PROPERTY ||
        meta.type === META_TYPE_HTTP_EQUIV ||
        meta.type === META_TYPE_ITEM_PROP
    )
    .filter((meta: Meta) => meta.property !== META_PROPERTY_TITLE)
    .map((meta: Meta) => {
      if (meta.type === META_TYPE_PROPERTY) {
        return {
          property: meta.property,
          content: meta.content,
        };
      }
      if (meta.type === META_TYPE_HTTP_EQUIV) {
        return {
          'http-equiv': meta.property,
          'content': meta.content,
        };
      }
      if (meta.type === META_TYPE_ITEM_PROP) {
        return {
          itemprop: meta.property,
          content: meta.content,
        };
      }
      return { name: meta.property, content: meta.content };
    });
};

const getLinks = (content: Content) => {
  if (!content?.metas) {
    return undefined;
  }
  return content.metas
    .filter((meta: Meta) => meta.type === META_TYPE_LINK || meta.type === META_TYPE_LINK_HREFLANG)
    .map((meta: Meta) => {
      if (meta.type === META_TYPE_LINK_HREFLANG || meta.property === 'alternate') {
        return { ...meta.content };
      }
      return { rel: meta.property, href: meta.content };
    });
};

export const metaDataService = {
  getMetas,
  getLinks,
  getTitle,
};
