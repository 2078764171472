import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';
import type { Color, CustomSpacing } from '@genially/public-web-components';
import { useEffect, useState } from 'react';

import type { FC } from 'react';
import { TestimonialChat } from '@genially/public-web-components';

interface ParagraphTestimonialsProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  testimonials?: {
    testimonial: {
      testimonial: {
        testimonial: {
          brand?: any;
          avatar: {
            avatar: {
              mediaImage: { alt: string; originalSrc: string; derivative?: { src: string } };
            };
          };
          name: string;
          role?: string;
          testimony: { value: string };
          response: { value: string };
          colorSet?: Color;
        };
      };
    };
  }[];
}

export const ParagraphTestimonials: FC<ParagraphTestimonialsProps> = ({
  behaviorSettings,
  testimonials,
}) => {
  const [colorSet, setColor] = useState('rBlack');

  useEffect(() => {
    const color = behaviorSettings.rowStyle.background
      ? behaviorSettings.rowStyle.background.baseColor.color === 'rBlack'
        ? 'rWhite'
        : 'rBlack'
      : 'rWhite';
    setColor(color);
  }, [behaviorSettings.rowStyle.background.baseColor.color]);
  const messages = testimonials
    ? testimonials.map(({ testimonial }) => {
        return [
          {
            brand: parseBrandColor(testimonial?.testimonial?.testimonial?.brand?.brand, colorSet),
            avatarSrc:
              testimonial?.testimonial?.testimonial?.avatar?.avatar.mediaImage.derivative?.src ||
              testimonial?.testimonial?.testimonial?.avatar?.avatar.mediaImage.originalSrc,
            avatarAlt: testimonial?.testimonial?.testimonial?.avatar?.avatar.mediaImage.alt,
            name: testimonial?.testimonial?.testimonial?.name,
            role: testimonial?.testimonial?.testimonial?.role,
            colorSet: colorSet,
            testimony: testimonial?.testimonial?.testimonial?.testimony
              ? testimonial?.testimonial?.testimonial?.testimony.value
              : '',
          },
        ];
      })
    : [];

  return (
    <TestimonialChat
      background={parseBackground(behaviorSettings.rowStyle.background)}
      customSpacing={behaviorSettings.rowStyle.spacing}
      messages={messages}
    />
  );
};

function parseBrandColor(brand: any, colorSet: string) {
  let brandSrc = { mediaImage: { src: '', alt: '' } };
  if (colorSet === 'rWhite') {
    brandSrc = brand?.testimonialLight
      ? brand?.testimonialLight?.entity
      : { mediaImage: { src: '', alt: '' } };
  } else {
    brandSrc = brand?.testimonial
      ? brand?.testimonial?.entity
      : { mediaImage: { src: '', alt: '' } };
  }
  return brandSrc;
}
