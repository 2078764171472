import { Cards, Color, CustomSpacing, TextAttributes } from '@genially/public-web-components';

import type { BackgroundDTO } from 'core/infrastructure/parseBackground';
import type { FC } from 'react';
import isNil from 'lodash/isNil';
import { parseBackground } from 'core/infrastructure/parseBackground';
import { parseMedia } from 'core/infrastructure/parseMedia';

interface ParagraphCardWithLinksButtonProps {
  url: { path: string };
  title: string;
}

interface ParagraphCardWithLinksButtonStyleProps {
  size: string;
  variant: string;
}

interface ParagraphCardsWithLinkProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  title?: string;
  titleType?: string;
  titleColor?: Color;
  heading?: string;
  headingType?: string;
  headingColor?: Color;
  subtitle?: string;
  subtitleType?: string;
  subtitleColor?: Color;
  paragraph?: { value: string };
  paragraphColor?: Color;
  firstSlider?: ParagraphCardsWithLinkItemDTO[];
  secondSlider?: ParagraphCardsWithLinkItemDTO[];
  buttonPrimary?: ParagraphCardWithLinksButtonProps;
  buttonPrimaryStyle?: ParagraphCardWithLinksButtonStyleProps;
  buttonSecondary?: ParagraphCardWithLinksButtonProps;
  buttonSecondaryStyle?: ParagraphCardWithLinksButtonStyleProps;
  categoryWrapper?: TemplateCategory;
  numberOfTemplates?: any;
  autoplay?: boolean;
  controls?: boolean;
  titlePosition?: string;
  titleURL?: {
    url?: { path: string };
  };
  langcode?: any;
}

export interface ParagraphCardsWithLinkItemDTO {
  item: any;
}

export interface TemplateCategory {
  category: {
    tid: string;
    mainRelatedContents: {
      entities: any[];
    };
  };
}

export const ParagraphCardsWithLink: FC<ParagraphCardsWithLinkProps> = ({
  behaviorSettings,
  title,
  titleType,
  titleColor,
  subtitle,
  subtitleType,
  subtitleColor,
  heading,
  headingType,
  headingColor,
  paragraph,
  paragraphColor,
  firstSlider,
  secondSlider,
  buttonPrimary,
  buttonPrimaryStyle,
  buttonSecondary,
  buttonSecondaryStyle,
  categoryWrapper,
  numberOfTemplates = 12,
  autoplay = 1,
  controls,
  titleURL,
  titlePosition,
  langcode,
}) => {
  const templates = categoryWrapper
    ? categoryWrapper.category.mainRelatedContents.entities?.filter(
        (template: any) => template.bundle === 'template'
      )
    : [];
  let maxTemplates = categoryWrapper
    ? templates.length <= numberOfTemplates
      ? templates.length
      : numberOfTemplates
    : numberOfTemplates;

  maxTemplates = numberOfTemplates === 0 ? templates.length : numberOfTemplates;

  const renderedTemplatesFirstRow = templates.slice(0, maxTemplates / 2);
  const renderedTemplatesSecondRow = templates.slice(maxTemplates / 2, maxTemplates);

  return (
    <Cards
      variant="carousel"
      autoplay={autoplay ? 1 : 0}
      hideControls={controls ? false : true}
      background={parseBackground(behaviorSettings.rowStyle.background)}
      customSpacing={behaviorSettings.rowStyle.spacing}
      heading={
        heading
          ? {
              text: heading,
              type: headingType,
              color: headingColor,
            }
          : undefined
      }
      title={
        title
          ? {
              text: title,
              type: titleType,
              color: titleColor,
            }
          : undefined
      }
      subtitle={
        subtitle
          ? {
              text: subtitle,
              type: subtitleType,
              color: subtitleColor,
            }
          : undefined
      }
      paragraph={
        paragraph
          ? {
              text: paragraph.value,
              color: paragraphColor,
            }
          : undefined
      }
      firstSlider={
        categoryWrapper
          ? parseCardsCarouselTemplateItems(renderedTemplatesFirstRow, langcode)
          : parseCardsCarouselItems(firstSlider, langcode)
      }
      secondSlider={
        categoryWrapper
          ? parseCardsCarouselTemplateItems(renderedTemplatesSecondRow, langcode)
          : parseCardsCarouselItems(secondSlider, langcode)
      }
      templateCard={categoryWrapper ? true : false}
      titleURL={titleURL}
      titlePosition={titlePosition}
      buttons={getButtons({
        buttonPrimary,
        buttonPrimaryStyle,
        buttonSecondary,
        buttonSecondaryStyle,
      })}
    />
  );
};

interface CarouselSlide {
  media?: any;
  subtitle?: TextAttributes;
  title?: TextAttributes;
  url?: string;
}

export const parseCardsCarouselItems = (
  items?: ParagraphCardsWithLinkItemDTO[],
  langcode?: string
): CarouselSlide[] | undefined => {
  if (!items || items.length == 0) return undefined;

  return items.map((item: ParagraphCardsWithLinkItemDTO) =>
    item.item.template
      ? {
          media: item?.item?.template?.entity?.teaserImage
            ? parseMedia(
                item.item.template.entity.teaserImage,
                true,
                langcode,
                item.item.template.entity.displayTitle ? item.item.template.entity.displayTitle : ''
              )
            : undefined,
          premium: item?.item?.template?.entity?.price,
          title: item?.item?.template?.entity?.displayTitle
            ? { text: item.item.template.entity.displayTitle }
            : undefined,
          url: item?.item?.template?.entity?.url ? item.item.template.entity.url.path : undefined,
          roundedBorder: true,
        }
      : {
          media: item.item.media ? parseMedia(item.item.media) : undefined,
          title: item.item.title
            ? { text: item.item.title, color: item.item.titleColor, type: item.item.titleType }
            : undefined,
          url: item.item.url ? item.item.url.url.path : undefined,
          roundedBorder: false,
        }
  );
};

export const parseCardsCarouselTemplateItems = (
  items?: any[],
  langcode?: any
): CarouselSlide[] | undefined => {
  if (!items || items.length == 0) return undefined;

  return items.map((item: any) => ({
    media: item.teaserImage
      ? parseMedia(item.teaserImage, true, langcode, item.displayTitle ? item.displayTitle : '')
      : undefined,
    premium: item.price,
    title: item.displayTitle ? { text: item.displayTitle } : undefined,
    url: item.url ? item.url.path : undefined,
  }));
};

export const getButtons = ({
  buttonPrimary,
  buttonPrimaryStyle,
  buttonSecondary,
  buttonSecondaryStyle,
}: any) => {
  const buttons = [];
  if (!isNil(buttonPrimary)) {
    buttons.push({
      variant: buttonPrimaryStyle.variant,
      size: buttonPrimaryStyle.size,
      children: buttonPrimary.title,
      href: buttonPrimary.url.path,
    });
  }

  if (!isNil(buttonSecondary)) {
    buttons.push({
      variant: buttonSecondaryStyle.variant,
      size: buttonSecondaryStyle.size,
      children: buttonSecondary.title,
      href: buttonSecondary.url.path,
    });
  }

  return buttons;
};
