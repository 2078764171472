export const templateAlt = (title: string, lang?: string) => {
  let alt = title;

  lang = lang === null || lang === undefined ? 'en' : lang;

  const formattedLang = lang !== '/' ? lang.replace(/\//g, '') : lang;

  switch (formattedLang) {
    case '/' || 'en': {
      alt = 'Interactive ' + title + ' template';
      break;
    }
    case 'es': {
      alt = 'Plantilla interactiva ' + title;
      break;
    }
    case 'fr': {
      alt = 'Modèle interactif ' + title;
      break;
    }
    case 'it': {
      alt = 'Template interattivo ' + title;
      break;
    }
    case 'pt-br': {
      alt = 'Modelo interativo ' + title;
      break;
    }
    case 'de': {
      alt = 'Interaktive Vorlage „' + title + '“';
      break;
    }
    default: {
      alt = 'Interactive ' + title + ' template';
      break;
    }
  }
  return alt;
};
