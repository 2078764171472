/* eslint-disable sort-imports */
import { BackgroundDTO, parseBackground } from 'core/infrastructure/parseBackground';
import { Color, CustomSpacing, List, ListItemProps } from '@genially/public-web-components';

import type { FC } from 'react';
import type { MediaIframeDTO } from 'core/domain/Media/MediaIframeDTO';
import type { MediaImageDTO } from 'core/domain/Media/MediaImageDTO';
import type { MediaVideoDTO } from 'core/domain/Media/MediaVideoDTO';
import type { TextVariantKey } from '@genially/public-web-components/src/components/atoms/Text/Text.definition';
import { parseMedia } from 'core/infrastructure/parseMedia';

interface ParagraphListProps {
  behaviorSettings: {
    rowStyle: { background: BackgroundDTO; spacing: CustomSpacing };
  };
  media?: MediaIframeDTO | MediaVideoDTO | MediaImageDTO;
  heading?: string;
  headingType?: string;
  headingColor?: Color;
  icon: { icon: string };
  iconSVG: any;
  iconColor: Color;
  iconSize?: string;
  title?: string;
  titleType?: string;
  titleColor?: Color;

  paragraph?: { processed: string };
  paragraphColor?: Color;

  alignment?: string;
  gapBetwItems?: string;
  columns?: number;
  items?: ListItemDTO[];

  buttonPrimary?: { url: { path: string }; title: string };
  buttonPrimaryStyle?: { size: string; variant: string };
  animation?: string;
}

export interface ListItemDTO {
  item: {
    title?: string;
    titleType?: TextVariantKey;
    titleColor?: string;
    direction?: string;
    paragraph?: { value: string };
    paragraphColor?: Color;
    paragraphType?: string;
    icon: any;
    iconSVG?: any;
    iconSize?: string;
    iconColor?: string;
    feature?: any;
    featureColor: string;
    featureIconColor: string;
    featureIconSize: string;
    featureParagraphColor: string;
  };
}

export const ParagraphList: FC<ParagraphListProps> = ({
  behaviorSettings,
  media,
  heading,
  icon,
  iconSVG,
  iconSize,
  iconColor,
  headingType,
  headingColor,
  title,
  titleType,
  titleColor,
  paragraph,
  paragraphColor,
  alignment,
  gapBetwItems,
  columns,
  items,
  buttonPrimary,
  buttonPrimaryStyle,
  animation = 'fadeInUp',
}) => {
  const bt = {
    variant: buttonPrimaryStyle?.variant,
    size: buttonPrimaryStyle?.size,
    children: buttonPrimary?.title,
    href: buttonPrimary?.url.path,
  };
  return (
    <List
      animation={animation}
      background={parseBackground(behaviorSettings?.rowStyle.background)}
      customSpacing={behaviorSettings?.rowStyle.spacing}
      media={parseMedia(media)}
      icon={{
        icon: { icon: iconSVG ? iconSVG : icon },
        iconSize: iconSize,
        iconColor: iconColor,
      }}
      alignment={alignment}
      gapBetwItems={gapBetwItems}
      columns={columns}
      heading={
        heading
          ? { text: heading, color: headingColor ?? 'rAkihabara', type: headingType }
          : undefined
      }
      title={title ? { text: title, color: titleColor ?? 'rBlack', type: titleType } : undefined}
      paragraph={
        paragraph ? { text: paragraph.processed, color: paragraphColor ?? 'rBlack' } : undefined
      }
      list={items ? parseListItems(items) : undefined}
      button={buttonPrimary ? bt : null}
    />
  );
};

export const parseListItems = (itemsDTO: ListItemDTO[] = []): ListItemProps[] => {
  if (!itemsDTO) return [];

  return itemsDTO.map((itemDTO: ListItemDTO) => {
    return {
      feature: itemDTO.item.feature,
      featureColor: itemDTO.item.featureColor,
      featureIconColor: itemDTO.item.featureIconColor,
      featureIconSize: itemDTO.item.featureIconSize,
      featureParagraphColor: itemDTO.item.featureParagraphColor,
      direction: itemDTO.item.direction,
      title: itemDTO.item.title,
      titleType: itemDTO.item.titleType,
      titleColor: itemDTO.item.titleColor ?? 'rBlack',
      paragraph: itemDTO.item.paragraph?.value,
      paragraphColor: itemDTO.item.paragraphColor ?? 'rBlack',
      paragraphType: itemDTO.item.paragraphType,
      icon: {
        name: itemDTO.item.iconSVG ? itemDTO.item.iconSVG : '',
        color: itemDTO.item.iconColor ? itemDTO.item.iconColor : 'rBlack',
        size: itemDTO.item.iconSize ? itemDTO.item.iconSize : 'small',
      },
    };
  });
};
